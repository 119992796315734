<template>
  <!-- eslint-disable -->
  <div class="stats">
    <div class="scroll">
      <div class="header">
        <img src="~@/assets/images/sally-logo-full.svg"/>
      </div>
      <div class="title">{{ $t('status_page.header') }}</div>
      <button
        class="export-button secondary" data-testid="export-button"
        @click="exportExcel">
        <span class="img">{{ $t('status_page.export_image') }}</span>
        <span>Export</span>
      </button>
      <div class="graph">
        <img src="~@/assets/images/stats-graph.jpeg"/>
      </div>
      <div class="detail-container">
        <div class="detail">
          <div class="counts">
            <div>
              {{ $t('status_page.total_survey') }} {{ stats.usageCounts.surveyCount }}
            </div>
            <div>
              {{ $t('status_page.number_participants') }} {{ stats.usageCounts.surveyOpenCount }}
            </div>
            <div>
              {{ $t('status_page.number_respondents') }} {{ stats.usageCounts.submitCount }}
            </div>
            <div>
              {{ $t('status_page.number_report') }} {{ stats.usageCounts.sendCustomerInfoCount }}
            </div>
            <div>
              {{ $t('status_page.number_request') }} {{ stats.usageCounts.requestConsultingCount }}
            </div>
          </div>
          <p>
          <div class="survey-summaries">
            <div class="each-survey" v-for="(eachSurvey, index) in stats.surveySummaries"
                 :key="index">
              <div class="item">{{ $t('status_page.survey_title') }} {{ eachSurvey.title }}</div>
              <div class="item">{{ $t('status_page.number_survey') }} {{ eachSurvey.count }}</div>
              <div class="item">{{ $t('status_page.UTM') }} {{ eachSurvey.utmSources.join(', ') }}
              </div>
              <br/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { formatDateTime } from '@/common/date.util';

export default {
  name: 'stats',
  data() {
    return {
      stats: {},
    };
  },
  async created() {
    this.stats = await this.getStats();
  },
  methods: {
    ...mapActions(['getStats', 'getUsage', 'downloadStatsExcelFile']),
    formatDate(date) {
      return formatDateTime(date);
    },
    async exportExcel() {
      await this.downloadStatsExcelFile();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';

.stats {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .scroll {
    overflow: auto;

    .header {
      height: 52px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      img {
        height: 32px;
      }
    }

    .graph {
      height: 600px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      img {
        width: 600px;
      }
    }

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 180px;
      background-color: #f4f4f6;
      font-size: $font_size_36;
      font-weight: 600;
      letter-spacing: normal;
      text-align: left;
      color: #2a324b;
    }

    .export-button {
      margin-left: 100px;
      margin-top: 20px;

      .img {
        width: 13px;
        height: 13px;
        background-size: 13px 13px;
        background-image: url('~@/assets/images/ico-export-excel-grey@3x.png');
        margin-right: 5px;
        text-indent: -999em;
      }

      &:hover {
        .img {
          background-image: url('~@/assets/images/ico-export-excel-active@3x.png');
        }
      }
    }

    .detail-container {
      display: flex;
      justify-content: center;

      .detail {
        display: flex;
        flex-direction: column;
        margin-bottom: 98px;
        max-width: $max_width;
        margin-left: $standard_margin_size;
        margin-right: $standard_margin_size;
        width: 100%;

        .counts {
          display: flex;
          flex-direction: column;
        }

        .survey-summaries {
          display: flex;
          flex-direction: column;

          .each-survey {
            display: flex;
            flex-direction: row;
            width: 100%;

            .item {
              flex: 1;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
